import React, { useState } from 'react';
import Button from "../Button/Button";
import './ProductItem.css';

const ProductItem = ({product, className, onAdd, delCount, addCount}) => {
    const [countItem, setCountItem] = useState(0) 
    const [addedItem, setAddedItem] = useState(false);
    const onAddHandler = () => {

        if (countItem == 0) {
            addCount(product)
            setCountItem(countItem + 1)
        }
        onAdd(product);
        setAddedItem(!addedItem)
    }

    const addCountHandler = () => {
        addCount(product)
        setCountItem(countItem + 1)
    }

    const delCountHandler = () => {
        delCount(product)
        if (countItem > 0) {
            setCountItem(countItem - 1)
        }
    }

    return (
        <div className={'product ' + className}>
            <div className={'img'}>
                <img src={product.img} alt={product.title} />
            </div>
            <div className={'title'}>{product.title}</div>
            <div className={'description'}>{product.description}</div>
            <div className={'price'}>
                <span>Стоимость: 
                    <br/><b>{product.price} руб.</b></span>
            </div>
            <div className='counter'>
            <Button className={'counter-btn'} onClick={delCountHandler}>
                -
            </Button>
            <span>{countItem}</span>
            <Button className={'counter-btn'} onClick={addCountHandler}>
                +
            </Button>
            </div>
           
            <Button className={'add-btn'} onClick={onAddHandler}>
               {addedItem ? 'Убрать' : 'Добавить'}
            </Button>
        </div>
    );
};

export default ProductItem;
