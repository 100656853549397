import React, {useState} from 'react';
import './ProductList.css';
import ProductItem from "../ProductItem/ProductItem";
import {useTelegram} from "../../hooks/useTelegram";
import {useCallback, useEffect} from "react";
import sushi_1 from '../../assets/sushi-1.png'
import sushi_2 from '../../assets/sushi-2.png'
import sushi_3 from '../../assets/sushi-3.png'

const products = [
    {id: '1', title: 'Самба-маракуйя', price: 625, description: `Состав: \nТропический соус маракуйя, спелый манго, авокадо, сливочный сыр креметте, семга.`, img: sushi_1, count: 0 },
    {id: '2', title: 'Креветка с лососем и соусом манго', price: 625, description: 'Состав: \nРис, нории, авокадо, креветка обжаренная в кляре, сыр креметто, семга, соус манго, микрозелень.', img: sushi_2, count: 0},
    {id: '3', title: 'Самба', price: 575, description: 'Состав: \nВнутри: сыр креметто, такуан, огурец. Снаружи: семга, гуакомоле, соус свит-чили', img: sushi_3, count: 0},
    // {id: '4', title: 'Куртка 8', price: 122, description: 'Зеленого цвета, теплая'},
    // {id: '5', title: 'Джинсы 3', price: 5000, description: 'Синего цвета, прямые'},
    // {id: '6', title: 'Куртка 7', price: 600, description: 'Зеленого цвета, теплая'},
    // {id: '7', title: 'Джинсы 4', price: 5500, description: 'Синего цвета, прямые'},
    // {id: '8', title: 'Куртка 5', price: 12000, description: 'Зеленого цвета, теплая'},
]

const getTotalPrice = (items = []) => {
    return items.reduce((acc, item) => {
        return acc += item.price * item.count
    }, 0)
}

const ProductList = () => {
    const [addedItems, setAddedItems] = useState([]);
    const {tg, queryId} = useTelegram();

    const onSendData = useCallback(() => {
        const data = {
            products: addedItems,
            totalPrice: getTotalPrice(addedItems),
            queryId,
        }
        // fetch('http://85.119.146.179:8000/web-data', {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify(data)
        // })
    }, [addedItems])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])

    const addCount = (product) => {
        products.map((item) => {
            if (item.id == product.id) {
                item.count++;
            }
        })
    }

    const delCount = (product) => {
        products.map((item) => {
            if (item.id == product.id && item.count > 0) {
                item.count--;
            }
        })
    }

    const onAdd = (product) => {
        const alreadyAdded = addedItems.find(item => item.id === product.id);
        let newItems = [];

        if(alreadyAdded) {
            newItems = addedItems.filter(item => item.id !== product.id);
        } else {
            newItems = [...addedItems, product];
        }

        setAddedItems(newItems)

        if(newItems.length === 0) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
            tg.MainButton.setParams({
                text: `Купить ${getTotalPrice(newItems)} руб.`
            })
        }
    }

    return (
        <div className={'list'}>
            {products.map(item => (
                <ProductItem
                    product={item}
                    onAdd={onAdd}
                    addCount={addCount}
                    delCount={delCount}
                    className={'item'}
                />
            ))}
        </div>
    );
};

export default ProductList;
